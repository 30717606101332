import React from "react";
import { FaTwitter, FaInstagram, FaLinkedinIn, FaTwitch } from "react-icons/fa";
import { FiX, FiMenu } from "react-icons/fi";
import Scrollspy from "react-scrollspy";

const SocialShare = [
  { Social: <FaTwitch />, link: "https://www.twitch.tv/simplymincylive" },
  {
    Social: <FaLinkedinIn />,
    link: "https://www.linkedin.com/in/antoinemincy/",
  },
  { Social: <FaInstagram />, link: "https://www.instagram.com/simplymincy" },
  { Social: <FaTwitter />, link: "https://twitter.com/SimplyMincy" },
];
const HeaderThree = (props) => {

  window.addEventListener("load", function () {
    console.log("All assets are loaded");
  });

  const menuTrigger = () => {
    document.querySelector(".header-wrapper").classList.toggle("menu-open");
  };

  const CLoseMenuTrigger = () => {
    document.querySelector(".header-wrapper").classList.remove("menu-open");
  };

  var elements = document.querySelectorAll(".has-droupdown > a");
  for (var i in elements) {
    if (elements.hasOwnProperty(i)) {
      elements[i].onclick = function () {
        this.parentElement.querySelector(".submenu").classList.toggle("active");
        this.classList.toggle("open");
      };
    }
  }
  const { color = "default-color" } = props;

  return (
    <header className={`header-area header-style-two header--fixed ${color}`}>
      <div className="header-wrapper">
        <div className="header-left d-flex align-items-center">
          <nav className="mainmenunav d-lg-block ml--50">
            <Scrollspy
              className="mainmenu"
              items={["home", "about", "portfolio", "blog", "contact"]}
              currentClassName="is-current"
              offset={-200}
            >
              <li>
                <a href="#home" onClick={CLoseMenuTrigger}>Home</a>
              </li>
              <li>
                <a href="#about" onClick={CLoseMenuTrigger}>About</a>
              </li>
              <li>
                <a href="#portfolio" onClick={CLoseMenuTrigger}>Portfolio</a>
              </li>
              <li>
                <a href="#blog"onClick={CLoseMenuTrigger}>Blog</a>
              </li>
            </Scrollspy>
          </nav>
        </div>
        <div className="header-right">
          <div className="social-share-inner">
            <ul className="social-share social-style--2 color-black d-flex justify-content-start liststyle">
              {SocialShare.map((val, i) => (
                <li key={i}>
                  <a href={`${val.link}`}>{val.Social}</a>
                </li>
              ))}
            </ul>
          </div>
          <div className="header-btn">
            <a className="rn-btn" href="#contact">
              <span>Contact</span>
            </a>
          </div>
          {/* Start Humberger Menu  */}
          <div className="humberger-menu d-block d-lg-none pl--20">
            <span onClick={menuTrigger} className="menutrigger text-white">
              <FiMenu />
            </span>
          </div>
          {/* End Humberger Menu  */}
          <div className="close-menu d-block d-lg-none">
            <span onClick={CLoseMenuTrigger} className="closeTrigger">
              <FiX />
            </span>
          </div>
        </div>
      </div>
    </header>
  );
};
export default HeaderThree;
