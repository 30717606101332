import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from "react-modal-video";
import { FaTwitter, FaInstagram, FaTwitch, FaLinkedinIn } from "react-icons/fa";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import HeaderTwo from "../component/header/HeaderTwo";
import FooterTwo from "../component/footer/FooterTwo";

const SocialShare = [
  { Social: <FaTwitch />, link: "https://www.twitch.tv/simplymincylive" },
  {
    Social: <FaLinkedinIn />,
    link: "https://www.linkedin.com/in/antoinemincy/",
  },
  { Social: <FaInstagram />, link: "https://www.instagram.com/simplymincy" },
  { Social: <FaTwitter />, link: "https://twitter.com/SimplyMincy" },
];

class PortfolioDetails extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <React.Fragment>
        <PageHelmet pageTitle="Portfolio" />

        <HeaderTwo homeLink="/" logo="symbol-dark" color="color-black" />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--34"
          data-black-overlay="7"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title theme-gradient">All-In-One Arcade</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Portfolio Details */}
        <div className="rn-portfolio-details ptb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="portfolio-details">
                  <div className="thumb mb--30">
                    <img
                      src="/assets/images/portfolio/mortalKombat.jpg"
                      alt="Portfolio Images"
                    />
                  </div>
                  <div className="inner">
                    <h2>Features</h2>
                    <p className="subtitle">
                      <li>2 player joysticks with 6 buttons </li>
                      <li>2 additional gamepads for 4 player support </li>
                      <li>Chromecast streaming </li>
                      <li>PS2 compatibility </li>
                      <li>Xbox compatibility </li>
                      <li>Dual Screen support for larger gaming groups </li>
                      <li>Light Gun support </li>
                    </p>

                    <p>
                      I still remember the first time I played an arcade game. I
                      was 7 and it was the summer of 93’. This summer I attended
                      a camp and every Wednesday we would go skating. My mother
                      would give me $5 to go buy an extra snack outside of the
                      food provided by camp. On this day however, a new game
                      showed up in the arcade, Mortal Kombat 2 and it had this
                      awesome intro, the sound was loud, and the colors were so
                      bright I could not resist spending my snack money on a
                      chance of climbing that tournament tower. From that point
                      on I was hooked. That day and everyday after that we went
                      to the skating rink for the summer. I was spending all my
                      money on Mortal Kombat. It was great. Up until that point
                      I would play games here and there but after that I really
                      fell in love with them and till this day I look forward to
                      my downtime when I can just sit back and play them. I’ve
                      collected quite a few games over the years but nothing
                      came close to that arcade feel. So, I started this
                      ultimate arcade project.
                    </p>

                    <p>
                      I’ve been researching all the stuff I wanted to add to my
                      arcade cabinet for years and I was very particular about
                      what I wanted. I needed it to be small enough to fit in a
                      confined space and more like a utility machine than just
                      an arcade only device. I did not want to buy something
                      like this because they were way too expensive and also
                      projects like this are just as fun to build as it is to
                      use. So I said if I ever had a chance to build one, I have
                      a good idea how to go about it. Then one day a friend of
                      mine was clearing up space and had an old arcade 1Up
                      cabinet laying around his house he wanted to toss. He
                      thought of me and gave it to me in hopes that I would
                      finally build the thing I have been planning like a mad
                      man the past couple years. So, let's jump into what was
                      done.
                    </p>
                    <div className="thumb mb--30">
                      <img
                        src="/assets/images/portfolio/Retropie_Splash.png"
                        alt="Portfolio Images"
                      />
                    </div>
                    <p>
                      First thing I did was research all the things needed to
                      accomplish the list of my wanted features. Looking for
                      answers came pretty quick as the arcade cabinet community
                      is a very passionate one. There were so many options to go
                      with, but it was pretty clear due to price, size, and
                      flexibility that I should go the retropie route(<em><a href="https://retropie.org.uk/">RetroPie Docs</a></em>).
                      Retropie allows you to turn your PC’s into a retro-gaming
                      machine. It was also built with a raspberry pi in mind and
                      being that I have done other projects with this mini
                      computer, it was a no brainer for me.
                    </p>
                    <p>
                      Next up was getting the basic materials. Most of the
                      raspberry pi pieces came in this kit
                      (<em><a href="https://www.amazon.com/gp/product/B07V5JTMV9/ref=ppx_yo_dt_b_search_asin_image?ie=UTF8&psc=1">Amazon Link</a></em>)
                      and I just had to find some decent arcade joysticks. As a
                      proof of concept I spent an afternoon getting my pi set up
                      and installed the retropie OS needed to run the arcade
                      games. Once again kudos to the community and documentation
                      here as this was a really straight forward process and I
                      was able to get something up and running that afternoon.
                    </p>
                    <div className="thumb mb--30">
                      <img
                        src="/assets/images/portfolio/connectingWires.png"
                        alt="Portfolio Images"
                      />
                    </div>
                    <p>
                      The next part was breaking down my current game room setup
                      and seeing what I currently had, I how that could
                      compliment what I wanted to do. At the end of the day, the
                      goal here is to keep costs down while building. Otherwise,
                      you might as well pay the thousands of dollars for a
                      premade cabinet. So, some key pieces I had was a
                      chromecasts stadia kit, which was given to me after a
                      google deal, a hdmi splitter, a hdmi switch, a projector,
                      a couple of extra ps5 controllers, a modded ps2 and xbox
                      that I did back in college and a old bluray surround sound
                      system.
                    </p>
                    <p>
                      At this point I figured I could set the cabinet up to
                      support all of these systems and different forms of media,
                      but the switching between all of them had to be easy or it
                      would be too cumbersome for me to truly enjoy it. So, I
                      ended up switching out the arcade cabinet screen
                      motherboard
                      (<em><a href="https://www.amazon.com/gp/product/B01MU14Z6L/ref=ppx_yo_dt_b_search_asin_image?ie=UTF8&psc=1">Amazon Link</a></em>)
                      to one that supported an hdmi input, traditional composite
                      input, and remote control connectivity so it can easily be
                      switched on and off without reaching around the cabinet.
                    </p>
                    <p>
                      After playing around with some cable management I was able
                      to get it to work pretty fluid while also not having it be
                      an eyesore in the family area. So, at this point the
                      cabinet was pretty operational now. I just needed to load
                      games and add a tad more flare to it. Finding the perfect
                      skin for the cabinet took awhile, because you only get one
                      attempt at skinning your cabinet. If the material is cheap
                      or the print is not clear, then you really just wasted
                      your money. Lucky for me I found the perfect one on etsy.
                      The seller
                      (<em><a href="https://www.etsy.com/shop/GulfCoastDecals?ref=simple-shop-header-name&listing_id=788335796">Decal Seller</a></em>)
                      not only had great reviews but had detailed instructions
                      on how to apply the skin and measured dimensions to make
                      sure it fitted your desired cabinet size.
                    </p>
                    <div className="thumb mb--30">
                      <img
                        src="/assets/images/portfolio/applyingDecal.png"
                        alt="Portfolio Images"
                      />
                    </div>
                    <p>
                      As far as games go, there were a few hiccups in the
                      controller configurations. Some consoles like n64,
                      dreamcast, playstation did not map well to the arcade
                      stick and with the small size of the cabinet, playing 4
                      player games was out of the question. So, the solution to
                      this was this usb dongle called 8bitdo
                      (<em><a href="https://www.amazon.com/gp/product/B07L334WXC/ref=ppx_yo_dt_b_search_asin_image?ie=UTF8&psc=1">Amazon Link</a></em>)
                      8bitdo is a company that specializes in retro gaming
                      controls suited for pc and cross console compatibility.
                      The product I ended up getting converted my ps5 controller
                      into a generic wireless pc controller. So, now when I turn
                      those ps5 controllers on the arcade cabinet recognizes it
                      and you can play the games on the arcade cabinet with no
                      problem at all.
                    </p>
                    <p>
                      I think the best thing about the cabinet is its
                      flexibility. With the hdmi switch and splitter I am able
                      to get the sound to not only play out of the arcade
                      cabinet but come through the surround sound and display on
                      the projector screen. Pairing that with the ability to
                      switch between thousands of arcade games, PS2, Xbox, and a
                      chromecast streaming device, it is a project that I am
                      really proud of and something that I know would have blown
                      my little 7 year old mind.
                    </p>
                    <p></p>
                    <div className="portfolio-thumb-inner">
                      <div className="thumb position-relative mb--30">
                        <img
                          src="/assets/images/portfolio/ArcadeVideoScreenShot.jpg"
                          alt="Portfolio Images"
                        />
                        <ModalVideo
                          channel="youtube"
                          isOpen={this.state.isOpen}
                          videoId="9lI-jaxwzVc"
                          onClose={() => this.setState({ isOpen: false })}
                        />
                        <button
                          className="video-popup position-top-center"
                          onClick={this.openModal}
                        >
                          <span className="play-icon"></span>
                        </button>
                      </div>
                    </div>
                    <h3>Future Updates</h3>
                    <p>
                      There was a kickstarter I supported back in September for
                      a lightgun that did not need a sensor bar and could
                      operate on any tv or projector. The order for the gun and
                      holster finally came on March 15th. Unfortunately I did
                      not have time to configure it properly for the cabinet.
                      So, I will update this walkthrough once I have completed
                      the set up.
                    </p>
                    <div className="portfolio-view-list d-flex flex-wrap">
                      <div className="port-view">
                        <span>Repo</span>
                        <h4>N/A</h4>
                      </div>

                      <div className="port-view">
                        <span>Project Type</span>
                        <h4>Fun</h4>
                      </div>

                      <div className="port-view">
                        <span>OS</span>
                        <h4>RetorPie</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Portfolio Details */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <FooterTwo />
      </React.Fragment>
    );
  }
}
export default PortfolioDetails;
