// React Required
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
// Create Import File
import "./index.scss";
import store from "../src/elements/store/index";

import PageScrollTop from "./component/PageScrollTop";

import DarkPortfolioLanding from "./dark/PortfolioLanding";
import Projects from "./elements/PortfolioDetails";
import ProjectInProgress from "./elements/PortfolioInProgress";
import ProjectMissionDetails from "./elements/PortfolioMissionDetails";
import ProjectArcadeDetails from "./elements/PortfolioArcadeDetails";
import BlogDetails from "./elements/BlogDetails";

import error404 from "./elements/error404";

import { BrowserRouter, Switch, Route } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";

const Root = () => {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <PageScrollTop>
        <Switch>
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/`}
            component={DarkPortfolioLanding}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/portfolio-details/ongoing`}
            component={ProjectInProgress}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/portfolio-details/mission`}
            component={ProjectMissionDetails}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/portfolio-details/arcade`}
            component={ProjectArcadeDetails}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/blog-details/:path`}
            component={BlogDetails}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/portfolio-details/*`}
            component={Projects}
          />

          <Route path={`${process.env.PUBLIC_URL}/404`} component={error404} />
          <Route component={error404} />
        </Switch>
      </PageScrollTop>
    </BrowserRouter>
  );
};

ReactDOM.render(
  <Provider store={store}>
    <Root />
  </Provider>,
  document.getElementById("root")
);
serviceWorker.register();
