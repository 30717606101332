import React from "react";

const PortfolioListContent = [
  {
    id: 1,
    image: "image-1",
    category: "Web Development",
    title: "Mission of Love Center",
    link: "https://missionoflovecenter.org/",
    details: "/portfolio-details/mission",
    month: "August 2021",
  },
  {
    id: 3,
    image: "image-3",
    category: "Fun Learning",
    title: "Magic Mirror",
    link: "/portfolio-details/ongoing",
    details: "/portfolio-details/ongoing",
    month: "October 2021",
  },
  {
    id: 4,
    image: "image-4",
    category: "Fun Learning",
    title: "All-In-One Arcade",
    link: "/portfolio-details/arcade",
    details: "/portfolio-details/arcade",
    month: "March 2022",
  },
  {
    id: 2,
    image: "image-2",
    category: "Web Development",
    title: "Nini Beats",
    link: "/portfolio-details/ongoing",
    details: "/portfolio-details/ongoing",
    month: "Q2 2022",
  },
  {
    id: 6,
    image: "image-6",
    category: "Web Development",
    title: "OST Website",
    link: "/portfolio-details/ongoing",
    details: "/portfolio-details/ongoing",
    month: "Q3 2022",
  },
  {
    id: 5,
    image: "image-5",
    category: "Mobile Development",
    title: "ClockEm App",
    link: "/portfolio-details/ongoing",
    details: "/portfolio-details/ongoing",
    month: "Q4 2022",
  },

];

const PortfolioList = (props) => {
  const { column, styevariation } = props;
  const list = PortfolioListContent.map((project) => (
    <div className={`${column}`} key={project.id}>
      <div className={`portfolio ${styevariation}`}>
        <div className="thumbnail-inner">
          <div className={`thumbnail ${project.image}`}></div>
          <div className={`bg-blr-image ${project.image}`}></div>
        </div>
        <div className="content">
          <div className="inner">
            <p>{project.category}</p>
            <h4>
              <a href={project.details}>{project.title}</a>
            </h4>
            <div className="portfolio-button">
              <a className="rn-btn" key={project.id} href={project.details}>
                Details
                <br />
                <i>{project.month}</i>
              </a>
            </div>
          </div>
        </div>

        <a className="link-overlay" href={project.link}>{}</a>
      </div>
    </div>
  ));
  return <React.Fragment>{list}</React.Fragment>;
};
export default PortfolioList;
