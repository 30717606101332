import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

class TabsTwo extends Component {
  render() {
    let tab1 = "Main skills",
      tab2 = "Experience",
      tab3 = "Education & Certification";
    const { tabStyle } = this.props;
    return (
      <div>
        {/* Start Tabs Area */}
        <div className="tabs-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <Tabs>
                  <TabList className={`${tabStyle}`}>
                    <Tab>{tab1}</Tab>
                    <Tab>{tab2}</Tab>
                    <Tab>{tab3}</Tab>
                  </TabList>

                  <TabPanel>
                    <div className="single-tab-content">
                      <ul>
                        <li>
                          <div>
                            Web and Mobile
                            <span> - Development</span>
                          </div>
                          - React (JS and TS)<br />
                          - React Native<br />
                          - AngularJs
                          <br />
                        </li>
                        <li>
                          <div>
                            QA <span> - Automation</span>
                          </div>
                          - Cypress <br />
                          - Karate (Open source testing framework built on
                          cucumber) <br />
                        </li>
                        <li>
                          <div>
                            Services
                            <span> - Dev Ops</span>
                          </div>
                          - Docker
                          <br />
                          - Google Cloud Platform
                          <br />
                          - Github <br />
                          - Jenkins
                          <br />
                        </li>
                      </ul>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="single-tab-content">
                      <ul>
                        <li>
                          <div>
                            Software Engineer
                            <span> - Foreground (Formerly Shootproof)</span>
                          </div>
                          2021 - Current
                        </li>
                        <li>
                          <div>
                            Automation Engineer<span> - ShootProof</span>
                          </div>
                          2018 - 2021
                        </li>
                        <li>
                          <div>
                            Systems Analyst<span> - Alight (Formerly Aon)</span>
                          </div>
                          2015- 2018
                        </li>
                      </ul>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="single-tab-content">
                      <ul>
                        <li>
                          <b>PSM I</b>
                          <span> - Trained and Certified by Scrum.org</span>
                          <a href="https://www.credly.com/badges/86c12845-8a96-49d6-a3c3-79879c43c683/public_url">
                            <u>Certified 2019</u>
                          </a>
                        </li>
                        <li>
                          <b>BA in Computer Science</b>{" "}
                          <span> - Mercer University</span>
                          <br />
                          <i>Minor in Mathematics </i>
                          <br />
                          2010
                        </li>
                      </ul>
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
        {/* End Tabs Area */}
      </div>
    );
  }
}

export default TabsTwo;
