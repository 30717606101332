import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";


const devBlogsURL =
  "https://dev.to/api/articles?username=simplymincy";

const BlogContent = () => {


  const [post, setPost] = useState(null);
  useEffect(() => {
    axios.get(devBlogsURL).then((response) => {
      setPost(response.data);
    });
  }, []);

  if (!post) return null;

  console.log(post);

  const list = post.map((blog) => (
    <div className="col-lg-4 col-md-6 col-12" key={blog.id}>
      <div className="blog blog-style--1">
        <div className="thumbnail">
          <a href="/blog-details">
            <img className="w-100" src={blog.social_image} alt="Blog Images" />
          </a>
        </div>
        <div className="content">
          {/* <p className="blogtype">{blog.description}</p> */}
          <h4 className="title">
            {blog.title}
          </h4>
          {/* <div className="blog-btn">
            <a
              className="rn-btn text-white"
              onClick={() => setPath(blog.path)}
              href="/blog-details"
            >
              {blogPath} Read More
            </a>
          </div> */}
          <div className="blog-btn">
            <Link
              className="rn-btn text-white"
              to={`/blog-details/${blog.slug}`}
            >
              Read
            </Link>
          </div>
        </div>
      </div>
    </div>
  ));

  return <React.Fragment>{list}</React.Fragment>;
};

export default BlogContent;
