import { createSlice, configureStore } from "@reduxjs/toolkit";

const initialState = { path: "https://dev.to/api/articles" };

const pathSlice = createSlice({
name: 'getPath',
initialState,
reducers: {
    setPath(state, action) {
        state.path = action.payload;
    }
}
});

const store = configureStore({
    reducer: pathSlice.reducer
});

export const pathActions = pathSlice.actions;

export default store;
