import React, { useEffect, useState } from "react";
import axios from "axios";
import ReactMarkdown from "react-markdown";
import { useParams } from "react-router-dom";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderTwo";
import Footer from "../component/footer/FooterTwo";

const BlogDetails = () => {
  const params = useParams();

  const blogPath = `https://dev.to/api/articles/simplymincy/${params.path}`;
  // const blogPath = `https://dev.to/api/articles/simplymincy/${window.location.pathname
  //   .split("/")
  //   .pop()}`;

  const [post, setPost] = useState();
  useEffect(() => {
    axios.get(blogPath).then((response) => {
      setPost(response.data);
    });
  }, [blogPath]);

  if (!post) return null;

  const markdown = post.body_markdown;

  console.log(window.location.pathname);
  console.log(window.location.href);

  return (
    <React.Fragment>
      <PageHelmet pageTitle="Blog Details" />
      <Header
        headertransparent="header--transparent"
        colorblack="color--black"
        logoname="logo.png"
      />

      {/* Start Breadcrump Area */}
      <div
        className="rn-page-title-area pt--120 pb--190 bg_image bg_image--34"
        data-black-overlay="7"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="blog-single-page-title text-center pt--100">
                <h2 className="title theme-gradient">{post.title}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Breadcrump Area */}

      {/* Start Blog Details */}
      <div className="rn-blog-details pt--110 pb--70 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="inner-wrapper">
                <div className="inner">
                  <div className="thumbnail">
                    <img src={post.cover_image} alt="Blog Images" />
                  </div>
                  <br />
                  <br />
                  <section>
                    <ReactMarkdown>{markdown}</ReactMarkdown>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Blog Details */}

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}

      <Footer />
    </React.Fragment>
  );
};
export default BlogDetails;
