import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import HeaderTwo from "../component/header/HeaderTwo";
import FooterTwo from "../component/footer/FooterTwo";

class PortfolioDetails extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <React.Fragment>
        <PageHelmet pageTitle="Portfolio" />

        <HeaderTwo homeLink="/" logo="symbol-dark" color="color-black" />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--34"
          data-black-overlay="7"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title theme-gradient">
                    Mission of Love Center
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Portfolio Details */}
        <div className="rn-portfolio-details ptb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="portfolio-details">
                  <div className="inner">
                    <h1>The Client's Request</h1>
                    <p>
                      The client had just filed for a nonprofit license. The
                      focus of their organization is to give back to those in
                      need. Services such as food drives, supply drives, and an
                      actual building for training, are all future visions for
                      the nonprofit. For now, they would simply like a way to
                      accept donations and have a page that illustrates their
                      future vision. All monetary donations should be linked
                      with givelify as they are a respected and secured way to
                      give when dealing with nonprofits.
                    </p>
                    <h3>Work Included:</h3>
                    <ul>
                      <li>
                        Securing a domain. <em>Used Google domains</em>
                      </li>
                      <li>
                        Building the app in React.
                        <em> Not required but wanted to work in React</em>
                      </li>
                      <li>Bootstrap </li>
                      <li>Setting up a server on Google Cloud Platform</li>
                      <li>Linking server and repo via docker for deployment</li>
                      <li>
                        Setting up a Gively profile and linking it to site.
                      </li>
                      <li>
                        Overall branding, color, copy, and style were done by me
                        as the client
                      </li>
                    </ul>
                    <br />

                    <div className="portfolio-view-list d-flex flex-wrap">
                      <div className="port-view">
                        <span>Repo</span>
                        <h4>Private</h4>
                      </div>

                      <div className="port-view">
                        <span>Project Type</span>
                        <h4>Non-Profit</h4>
                        <h4>Website</h4>
                      </div>

                      <div className="port-view">
                        <span>Hosting</span>
                        <h4>Google Cloud Platform</h4>
                      </div>
                    </div>
                    <div className="view-more-btn mt--60 mt_sm--30 text-center">
                      <a
                        className="rn-button-style--2 btn-solid"
                        href="https://missionoflovecenter.org/"
                      >
                        <span>Go To</span>
                        <br />
                        <span>Mission Of Love Center</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Portfolio Details */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <FooterTwo />
      </React.Fragment>
    );
  }
}
export default PortfolioDetails;
